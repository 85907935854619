<template>
  <GuestLayout>
    <Head title="Log in" />

    <form @submit.prevent="submit">
      <div class="flex flex-col items-center space-y-8">
        <div class="font-bold text-3xl text-gray-800 text-center">Welcome to Eternal Plans</div>
        <div class="text-gray-500 text-center">Use your email to start using the Eternal Plans platform</div>
        <div class="md:w-96 flex flex-col w-full flex-1 space-y-6">
          <EpAlert v-if="status" status="success" hide-close>{{ status }}</EpAlert>
          <EpAlert v-if="$page.props.flash.error" status="error" compact hide-close>
            {{ $page.props.flash.error }}
          </EpAlert>
          <div>
            <EpInput
              ref="input"
              v-model="form.email"
              class="w-full"
              placeholder="Email address"
              :invalid="!!form.errors.email"
            >
              <template #hint>{{ form.errors.email }}</template>
            </EpInput>
          </div>
          <EpButton class="w-full" :loading="loading">Continue</EpButton>
          <div class="text-center">
            Don't have an account?
            <Link :href="route('register')" class="text-indigo-600 hover:underline dark:text-indigo-500">Sign up</Link>
          </div>
        </div>
      </div>
    </form>
  </GuestLayout>
</template>

<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import { onMounted, ref } from 'vue';

import EpAlert from '@/Components/EpAlert';
import EpButton from '@/Components/EpButton';
import EpInput from '@/Components/EpInput';
import GuestLayout from '@/Layouts/GuestLayout.vue';

defineProps({
  canResetPassword: {
    type: Boolean,
  },
  status: {
    type: String,
    required: true,
  },
});

const input = ref(null);
const loading = ref(false);
const form = useForm({
  email: '',
  remember: false,
});

const submit = () => {
  loading.value = true;
  form.post(route('login', route().queryParams), {
    onSuccess: () => form.reset(),
    onFinish: () => (loading.value = false),
  });
};

onMounted(() => {
  input.value.$refs.input.focus();
});
</script>
